import { createSignal, Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import IconWarning from '~/assets/images/common/linear-warning.svg?component-solid';
import IconArrowDown from '~/assets/images/lease-template/arrowDown.svg';
import IconAutofillFiled from '~/assets/images/lease-template/autofill.svg?component-solid';
import IconDateField from '~/assets/images/lease-template/dateFiled.svg?component-solid';
import IconInitialField from '~/assets/images/lease-template/initialFeild.svg?component-solid';
import IconSignatureField from '~/assets/images/lease-template/signatureField.svg?component-solid';
import IconTextFiled from '~/assets/images/lease-template/textFiled.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import LabeledSelect from '~/components/common/Inputs/LabeledSelect';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/pdfsigner/hooks/usePresenter';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { CurrentAnnotationPresenter } from '~/pdfsigner/presenters/CurrentAnnotationPresenter';
import { PdfPresenter } from '~/pdfsigner/presenters/PdfPresenter';
import { ToolbarPresenter } from '~/pdfsigner/presenters/ToolbarPresenter';
import { AddPdfUseCase } from '~/pdfsigner/usecases/addPdfUseCase';
import { ChangeFontSizeUseCase } from '~/pdfsigner/usecases/changeFontSizeUseCase';
import { DeleteAnnotationUseCase } from '~/pdfsigner/usecases/deleteAnnotationUseCase';
import { SetAnnotationDataPathKeyUseCase } from '~/pdfsigner/usecases/setAnnotationDataPathKeyUseCase';
import { SetAnnotationTextUseCase } from '~/pdfsigner/usecases/setAnnotationTextUseCase';
import { cn } from '~/utils/classnames';
import { PdfDocument } from './PdfDocument';
import { FontSizeSelector } from './toolbar/FontSizeSelector';
import { DropdownWithTree } from './toolbar/TreeDropdown';
import type { JSX } from 'solid-js';
import type { DataPathNode } from '~/pdfsigner/usecases/types/dataPathNode';

const DocumentUpload = (): JSX.Element => {
  const { t } = useLocalization();
  let pdfDocumentRef!: any;

  const { execute: addPdf } = useUseCase(AddPdfUseCase);
  const { model: presentablePdf } = usePresenter(PdfPresenter);
  const { model: currentAnnotation } = usePresenter(CurrentAnnotationPresenter);
  const { execute: changeFontSize } = useUseCase(ChangeFontSizeUseCase);
  const { model: toolbar } = usePresenter(ToolbarPresenter);
  const { execute: deleteAnnotation } = useUseCase(DeleteAnnotationUseCase);
  const { execute: setText } = useUseCase(SetAnnotationTextUseCase);
  const { execute: setDataPathKey } = useUseCase(SetAnnotationDataPathKeyUseCase);

  const [pdfSize, setPdfSize] = createSignal<string>('w-full');
  const pdfSizeOptions = [
    {
      label: '25%',
      value: 'w-1/4',
    },
    {
      label: '50%',
      value: 'w-1/2',
    },
    {
      label: '75%',
      value: 'w-9/12',
    },
    {
      label: '100%',
      value: 'w-full',
    },
  ];

  const annotations = {
    signature: { title: t('Signature'), icon: IconSignatureField },
    initials: { title: t('Initials'), icon: IconInitialField },
    dateTime: { title: t('Date'), icon: IconDateField },
    text: { title: t('Text'), icon: IconTextFiled },
    autofill: { title: t('Autofill'), icon: IconAutofillFiled },
  };

  const handleDrop = async (event: DragEvent) => {
    event.preventDefault();
    await addPdf(event.dataTransfer?.files);
  };

  const preventDefaultBehavior = (event: DragEvent) => {
    event.preventDefault();
  };

  const isPdfLoaded = () => {
    const pdf = presentablePdf();
    return pdf !== undefined && pdf?.length > 0;
  };

  const onChangePdfSize = (val: any) => {
    setPdfSize(val);
    pdfDocumentRef.handleResize();
  };

  const handleAnnotationRemove = async () => {
    await deleteAnnotation({ id: currentAnnotation()?.selectedAnnotation?.id });
  };

  const handleAnnotationInput = async (value: string) => {
    await setText({ id: currentAnnotation()?.selectedAnnotation?.id, value });
  };

  const onChangeSelectedNode = (node: DataPathNode) => {
    setText({ id: currentAnnotation()?.selectedAnnotation?.id, value: node.name });
    setDataPathKey({
      id: currentAnnotation()?.selectedAnnotation?.id,
      value: node.key,
    });
  };

  return (
    <div class="flex h-full min-w-0 grow flex-col rounded-lg bg-[#E6E6E6]">
      <div class="flex h-[56px] min-h-[56px]  items-center justify-between bg-white px-4">
        <div class="flex items-center gap-2">
          <Show when={currentAnnotation()?.selectedAnnotationType || currentAnnotation()?.selectedAnnotation}>
            <div class="flex items-center gap-1.5 text-sm text-essential-colour">
              <Dynamic
                component={
                  annotations[
                    (currentAnnotation()?.selectedAnnotationType ||
                      currentAnnotation()?.selectedAnnotation?.type) as keyof typeof annotations
                  ]?.icon
                }
              />
              <span>
                {
                  annotations[
                    (currentAnnotation()?.selectedAnnotationType ||
                      currentAnnotation()?.selectedAnnotation?.type) as keyof typeof annotations
                  ]?.title
                }
              </span>
            </div>
          </Show>
          <Show when={currentAnnotation()?.selectedAnnotationType}>
            <div class="flex items-center gap-1">
              <IconWarning />
              <span class="text-sm text-warning">{t('Click Create in the page')}</span>
            </div>
          </Show>
          <Show when={currentAnnotation()?.selectedAnnotation}>
            <Show when={currentAnnotation()?.selectedAnnotation?.type === 'text'}>
              <LabeledTextInput prepend={t('Text')} value={currentAnnotation()?.selectedAnnotation?.text} onInput={handleAnnotationInput} />
            </Show>
            <Show when={currentAnnotation()?.selectedAnnotation?.type === 'autofill'}>
              <DropdownWithTree
                data={toolbar()?.dataPaths}
                selectedNode={toolbar()?.selectedNode}
                enabled={true}
                onSelect={onChangeSelectedNode}
                triggerElement={
                  <div class="flex cursor-pointer items-center rounded-lg border border-input-border pl-4 text-text-level02 transition-all hover:border-primary">
                    <span class="text-sm font-medium">{toolbar()?.selectedNode?.name ?? t('Please select')}</span>
                    <div class="inline-flex items-center justify-center p-2.5">
                      <img src={IconArrowDown} />
                    </div>
                  </div>
                }
              />
            </Show>

            <FontSizeSelector options={toolbar()?.fontSizeOptions} value={toolbar()?.selectedFontSize} onInput={changeFontSize} />
            <Button variant="outlined" color="warning" onClick={handleAnnotationRemove}>
              {t('Remove')}
            </Button>
          </Show>
        </div>
        <LabeledSelect
          selectClass="bg-white"
          class="w-24"
          labelClass="capitalize text-text-level03"
          options={pdfSizeOptions}
          value={pdfSize()}
          onInput={onChangePdfSize}
        />
      </div>
      <div
        id="pdf-viewer"
        class="thinscroll h-[calc(100%-60px)] max-h-full overflow-hidden overflow-y-auto p-1"
        onDrop={handleDrop}
        onDragOver={preventDefaultBehavior}
        onDragLeave={preventDefaultBehavior}>
        <Show
          when={isPdfLoaded()}
          fallback={<div class="flex h-full items-center justify-center bg-gray-100">Drag and drop a PDF file here</div>}>
          <div class={cn('mx-auto', pdfSize())}>
            <PdfDocument pages={presentablePdf() || []} ref={pdfDocumentRef} />
          </div>
        </Show>
      </div>
    </div>
  );
};

export default DocumentUpload;
