import { For } from 'solid-js';
import IconAutofillFiled from '~/assets/images/lease-template/autofill.svg';
import IconDateField from '~/assets/images/lease-template/dateFiled.svg';
import IconInitialField from '~/assets/images/lease-template/initialFeild.svg';
import IconSignatureField from '~/assets/images/lease-template/signatureField.svg';
import IconTextFiled from '~/assets/images/lease-template/textFiled.svg';
import { TintedSVG } from '~/components/common/TintedSvg';
import { useLocalization } from '~/contexts/global';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { AnnotationType } from '~/pdfsigner/usecases/types/annotation';
import { UpdateLeaseTemplateUseCase } from '~/pdfsigner/usecases/updateLeaseTemplateUseCase';
import { cn } from '~/utils/classnames';
export interface DataFieldsSelectorProps {
  selectedAnnotationType?: AnnotationType;
  onChangeSelectedAnnotationType: (value: AnnotationType) => void;
}

const { TEXT, INITIALS, SIGNATURE, DATE, AUTOFILL } = AnnotationType;

export const DataFieldsSelector = (props: DataFieldsSelectorProps) => {
  const { execute: updateLeaseTemplate } = useUseCase(UpdateLeaseTemplateUseCase);

  const { t } = useLocalization();
  const dataFieldOptions = [
    { label: t('Signature'), icon: IconSignatureField, value: SIGNATURE },
    { label: t('Initials'), icon: IconInitialField, value: INITIALS },
    { label: t('Date'), icon: IconDateField, value: DATE },
    { label: t('Text'), icon: IconTextFiled, value: TEXT },
    { label: t('Autofill'), icon: IconAutofillFiled, value: AUTOFILL },
  ];

  return (
    <div class="flex flex-col gap-2">
      <p class="text-sm text-text-level03">{t('Annotations')}</p>
      <For each={dataFieldOptions}>
        {(option) => (
          <div
            class={cn(
              'flex w-full cursor-pointer items-center gap-1.5 rounded-lg border border-input-border px-5 py-2.5 text-text-level02 transition-all hover:border-primary',
              { 'bg-primary border-primary text-white': props.selectedAnnotationType === option.value }
            )}
            onClick={() => {
              props.onChangeSelectedAnnotationType(option.value);
              option.value === 'autofill' && updateLeaseTemplate({ dataPathKey: 'unit/address' });
            }}>
            <TintedSVG svg={option.icon} color={props.selectedAnnotationType === option.value ? '#FFFFFF' : '#464F74'} />
            <span class="text-sm text-inherit">{option.label}</span>
          </div>
        )}
      </For>
    </div>
  );
};
