import { Show, createSignal, createEffect, For } from 'solid-js';
import IconDocument from '~/assets/images/common/document.svg?component-solid';
import IconRemove from '~/assets/images/common/remove.svg?component-solid';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Modal } from '~/components/modals/Modal';
import { DragAndDrop, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useAttachments } from '~/contexts/local';
import { FileIcon } from './FileTitle';
import type { Component } from 'solid-js';

export type NewFileReadyToUpload = { type: string; fileName: string; description?: string; file: File };

export type FormErrors = {
  name?: string;
  description?: string;
};
export const FileUploadModal: Component<{
  entryType?: `${MagicDoor.Api.EntityType}`;
  entryId?: string;
  open: boolean;
  disableDescription?: boolean;
  accept?: string;
  allowPdfOnly?: boolean;
  allowNameInput?: boolean;
  file?: File;
  pending?: boolean;
  onClose: () => void;
  onConfirm?: (categoryId: string, files: File[], name: string, description?: string) => Promise<boolean | undefined>;
  nameRequired?: boolean;
  descriptionRequired?: boolean;
  errors?: FormErrors;
}> = (props) => {
  const { t } = useLocalization();
  const [description, setDescription] = createSignal<string>('');
  const [files, setFiles] = createSignal<File[]>([]);
  const [name, setName] = createSignal<string>('');

  const { addAttachment, cancelAttachment } = useAttachments();

  createEffect(() => {
    if (props.open && props.file) {
      setFiles(() => [props.file].filter((file): file is File => !!file));
    }
  });

  const handleDropFiles = (fileList: FileList) => {
    const droppedFiles = Array.from(fileList);
    const validFiles = droppedFiles.filter((file) => {
      if (file.size > 100 * 1024 * 1024) {
        toast.error(t('Attachment file should not exceed 100MB'));
        return false;
      }
      if (props.accept && file.type !== props.accept) {
        toast.error(`Only ${props.accept} files are allowed`);
        return false;
      }
      return true;
    });
    setFiles((prev) => [...prev, ...validFiles]);
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleClose = () => {
    cancelAttachment();
    props.onClose();
  };

  const handleConfirm = async () => {
    const currentFiles = files();
    if (currentFiles.length === 0) return;
    if (props.onConfirm) {
      const result = await props.onConfirm(props.entryId || '', currentFiles, name(), description());
      if (result === false) return;
    } else if (props.entryType && props.entryId) {
      for (const file of currentFiles) {
        await addAttachment(props.entryType, props.entryId, file, description());
      }
    }

    props.onClose();
  };

  createEffect(() => {
    if (!props.open) {
      setFiles([]);
      setDescription('');
      setName('');
    }
  });

  return (
    <Modal
      title={t('Upload file')}
      visible={props.open}
      doneText={t('Upload')}
      onCancel={handleClose}
      onDone={handleConfirm}
      loading={addAttachment.pending || props.pending}
      confirmation={files().length > 0 || description() ? undefined : false}>
      <DragAndDrop
        multiple
        accept={props.accept}
        class="m-8 flex h-52 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-primary text-text-level03 transition-colors data-[active]:bg-light-pink data-[active]:shadow-inner"
        onDropFiles={handleDropFiles}>
        <IconDocument class="mt-2" />
        <p class="my-2 text-xs">{t('Drag and drop files here')}</p>
        <span class="rounded-full border border-primary px-5 py-2 text-sm text-primary">{t('Select from computer')}</span>
      </DragAndDrop>

      <Show when={files().length > 0}>
        <div class="mx-8 mt-4 flex flex-wrap gap-4">
          <For each={files()}>
            {(file, index) => (
              <div class="flex w-[calc(50%-0.5rem)] items-center justify-between gap-2 rounded-md border border-gray-200 p-2">
                <div class="flex items-center gap-2 overflow-hidden">
                  <FileIcon class="size-5 shrink-0" filename={file.name} />
                  <span class="truncate text-sm">{file.name}</span>
                </div>
                <button class="ml-2 shrink-0" onClick={() => handleRemoveFile(index())}>
                  <IconRemove class="cursor-pointer text-link" />
                </button>
              </div>
            )}
          </For>
        </div>
      </Show>

      <Show when={props.allowNameInput}>
        <LabeledTextInput
          required={props.nameRequired}
          maxlength={150}
          class="m-8 mt-4"
          label={t('Name')}
          placeholder={t('Enter name')}
          value={name()}
          onInput={setName}
          error={props.errors?.name}
        />
      </Show>
      <Show when={!props.disableDescription}>
        <LabeledTextInput
          required={props.descriptionRequired}
          class="m-8 mt-4"
          label={t('Description')}
          placeholder={t('Enter description')}
          value={description()}
          onInput={setDescription}
          maxlength={200}
          error={props.errors?.description}
        />
      </Show>
    </Modal>
  );
};
