import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';

export interface FontSizeSelectorProps {
  onInput: (value: number | string) => void;
  options?: { value: number; label: string }[];
  value?: number;
}

export const FontSizeSelector = (props: FontSizeSelectorProps) => {
  return (
    <LabeledSelect
      selectClass="bg-white"
      labelClass="capitalize text-text-level03"
      class="w-20"
      options={props.options ?? []}
      value={props.value}
      onInput={props.onInput}
    />
  );
};
